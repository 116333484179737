import React, {useEffect} from 'react';

import Layout from '@/components/layout';

const IndexPage = (): React.JSX.Element => {

  const redirectTo = (): void => {
    setTimeout((): string => window.location.href = `https://golito.co`, 2000);
  };

  useEffect((): void => redirectTo(), []);

  return (
    <Layout title={'Golito | Administra listas y pagos para tus eventos en equipo'} path={''} description={'Administra las listas y los pagos de tus amigos y conocidos para tus eventos en equipo'}>
      <div className={'MainText'}>Redireccionando&hellip;</div>
    </Layout>
  );
};

export default IndexPage;
